import contextTrigger from 'lib/util/contextTrigger';
import ModuleManager from 'lib/util/ModuleManager';



import Button from '00-base/Button/Button';



import Slider from '01-widgets/Slider/Slider';



import CardsRow from '02-content/CardsRow/CardsRow';



import CompleteCookieConsent from '02-content/CompleteCookieConsent/CompleteCookieConsent';



import ContactForm from '02-content/ContactForm/ContactForm';



import HomeExperience from '02-content/HomeExperience/HomeExperience';



import ImageTextBlend from '02-content/ImageTextBlend/ImageTextBlend';



import ImagesAroundText from '02-content/ImagesAroundText/ImagesAroundText';



import Location from '02-content/Location/Location';



import NewsSlider from '02-content/NewsSlider/NewsSlider';



import NextArticleTeaser from '02-content/NextArticleTeaser/NextArticleTeaser';



import Panorama from '02-content/Panorama/Panorama';



import SocialMediaFeed from '02-content/SocialMediaFeed1/SocialMediaFeed';



import TeaserCards from '02-content/TeaserCards/TeaserCards';



import VideoCta from '02-content/VideoCta/VideoCta';



import Header from '03-global/Header/Header';



import LangSelect from '03-global/LangSelect/LangSelect';



import Logo from '03-global/Logo/Logo';



export default (function() {
	let time = new Date();


	contextTrigger.add('[data-js="Button"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Button));
	});


	contextTrigger.add('[data-js="Slider"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Slider));
	});


	contextTrigger.add('[data-js="CardsRow"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(CardsRow));
	});


	contextTrigger.add('[data-js="CompleteCookieConsent"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(CompleteCookieConsent));
	});


	contextTrigger.add('[data-js="ContactForm"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(ContactForm));
	});


	contextTrigger.add('[data-js="HomeExperience"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(HomeExperience));
	});


	contextTrigger.add('[data-js="ImageTextBlend"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(ImageTextBlend));
	});


	contextTrigger.add('[data-js="ImagesAroundText"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(ImagesAroundText));
	});


	contextTrigger.add('[data-js="Location"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Location));
	});


	contextTrigger.add('[data-js="NewsSlider"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(NewsSlider));
	});


	contextTrigger.add('[data-js="NextArticleTeaser"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(NextArticleTeaser));
	});


	contextTrigger.add('[data-js="Panorama"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Panorama));
	});


	contextTrigger.add('[data-js="SocialMediaFeed"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(SocialMediaFeed));
	});


	contextTrigger.add('[data-js="TeaserCards"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(TeaserCards));
	});


	contextTrigger.add('[data-js="VideoCta"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(VideoCta));
	});


	contextTrigger.add('[data-js="Header"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Header));
	});


	contextTrigger.add('[data-js="LangSelect"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(LangSelect));
	});


	contextTrigger.add('[data-js="Logo"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Logo));
	});



	contextTrigger.validate(document.body);

	console.log('Selecting components took: ', new Date() - time, 'ms');
}());
